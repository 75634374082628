
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        























































































































































































































































.submit {
  @include loading($c-orange);

  display: flex !important; /* stylelint-disable-line declaration-no-important */
  margin-left: auto;
}

.token {
  @extend %shadow;

  padding-right: col(1);
  padding-left: col(1);
  border-radius: 20px;
  margin-top: 4rem;

  @include mq(m) {
    padding-right: col(1, 14);
    padding-left: col(1, 14);
  }
}

.token-intro {
  font-weight: 700;
  color: #ff4b00;
}
